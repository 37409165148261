@import "../../../../css/colors.scss";

#ClientErrorDialog {
  position: absolute;
  max-width: 40vw;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5000 !important;
}

#ServerErrorDialog {
  position: absolute;
  max-width: 40vw;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5000 !important;
}

.dialog-title {
  font-size: 1.5rem !important;
}

.close-icon {
  height: 2rem;
  width: 2rem;
  background-position: center;
  background-repeat: no-repeat;
}

.error-message {
  background: $white;
  color: black;
  padding: 1rem;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}

.mdc-dialog__content {
  padding-bottom: 0 !important;
}

#ServerErrorDialog > .mdc-dialog__container > .mdc-dialog__surface {
  background-color: $dialog-error-server !important;
}

#ClientErrorDialog > .mdc-dialog__container > .mdc-dialog__surface {
  background-color: $dialog-error-client !important;
}

.mat-mdc-dialog-actions button {
  color: $white;
  padding: 0.5rem;
  border: 1px solid $white !important;
}

.mat-mdc-dialog-actions button:hover {
  filter: brightness(0.85);
}

.dialog-backdrop {
  position: fixed;
  // z-index: 4000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

#ServerErrorDialog .dialog-close-button {
  background-color: $dialog-error-server !important;
}

#ClientErrorDialog .dialog-close-button {
  background-color: $dialog-error-client !important;
}
