import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ConfirmDialog } from 'csoft-library/modelli';

@Component({
  selector: 'lib-confirm-dialogue',
  templateUrl: './confirm-dialogue.component.html',
  styleUrl: './confirm-dialogue.component.scss',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [MatDialogModule],
})
export class ConfirmDialogueComponent {
  title: string = 'Confirm';
  message: string = '';
  btnOkText: string = '';
  btnCancelText: string = '';

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialog
  ) {
    this.message = data.message;
    this.btnOkText = data.confirm || 'OK';
    this.btnCancelText = data.cancel || 'Cancel';
  }

  confirm() {
    this.dialogRef.close(true);
  }

  decline() {
    this.dialogRef.close(false);
  }
}
