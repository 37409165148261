import { Inject, Injectable } from '@angular/core';
import { Environment } from 'csoft-library/modelli';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  profileId: string = '0';
  username: string = '';

  constructor(@Inject('env') private environment: Environment) {}

  getHeader(): HttpHeaders {
    return new HttpHeaders()
      .set('content-type', 'application/json')
      .set(
        'Access-Control-Allow-Origin',
        this.environment.protocol + '//' + this.environment.domain
      )
      .set('ProfileId', this.profileId)
      .set('UsrName', this.username)
      .set('lang', this.environment.api_lang)
      .set(
        'level',
        this.environment.protocol + '//' + this.environment.domain + '/'
      );
  }

  getReportHeader(): HttpHeaders {
    return new HttpHeaders().set('content-type', 'application/json');
  }
}
