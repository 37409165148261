import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from 'csoft-library/servizi';
import { catchError, from, map, of, switchMap } from 'rxjs';

export const authGuard: CanActivateFn = (route, state) => {
  const AuthSvc = inject(AuthService);

  return from(AuthSvc.IsAuthenticated()).pipe(
    switchMap((authenticated) => {
      if (authenticated) return of(authenticated);
      else
        return AuthSvc.RefreshToken().pipe(
          map(() => true),
          catchError(() => {
            AuthSvc.LogOutUser();
            return of(false);
          })
        );
    })
  );
};
