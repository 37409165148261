import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Environment, NomeValore, UserData } from 'csoft-library/modelli';
import { HeaderService } from '../header.service';
import { firstValueFrom, Subject } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  destroy$ = new Subject<void>();
  User: UserData | null = null;
  // hasLoadedUser: boolean = false;
  name: string = '';
  avatar: string = '';
  currLevel: NomeValore;
  // username: string = '';

  constructor(
    private http: HttpClient,
    @Inject('env') private environment: Environment,
    private Header: HeaderService,
    private AuthSvc: AuthService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //Recupera lo user tramite il cookie
  public async getCurrentUser() {
    const username = await this.AuthSvc.getUserClaim();

    if (username) {
      this.Header.username;

      const url = `${this.environment.ApiDomain}api/users/GetUserByName`;

      this.User = await firstValueFrom(
        this.http.get<UserData>(url, {
          headers: this.Header.getHeader(),
          withCredentials: true,
          params: { username: username },
        })
      ).catch((err) => {
        console.error(err);
        return null;
      });

      console.log('User', this.User);

      if (!this.User) return;

      // //Utile per permettere al terzo livello di caricare lo user prima di qualsiasi vista
      // this.hasLoadedUser = true;

      this.Header.profileId = this.User.ProfileId.toString();

      this.name = this.User?.Name + ' ' + this.User?.Surname;
      this.avatar = this.environment.MediaDomain + this.User?.Avatar;

      let isallowed = false;
      for (let i = 0; i < this.User.Levels.length; i++) {
        if (
          this.User.Levels[i].name == this.environment.domain ||
          this.environment.domain.includes('carview') //utile per il terzo livello di login "carview"
        ) {
          isallowed = true;
          this.currLevel = this.User.Levels[i];
          break;
        }
      }

      if (!isallowed || !this.User.IsApproved || this.User.IsLockedOut) {
        this.AuthSvc.LogOutUser();
      }
    }
  }
}
