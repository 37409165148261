@import "../../../../css/colors.scss";

#ConfirmDialog {
  position: absolute;
  max-width: 40vw;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5000 !important;
}

.dialog-title {
  font-size: 1.5rem !important;
}

#ConfirmDialog > .mdc-dialog__container > .mdc-dialog__surface {
  background-color: $primary !important;
}

.confirm-message {
  background: $white;
  color: black;
  padding: 1rem;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}

.dialog-backdrop {
  position: fixed;
  // z-index: 4000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.confirm-action {
  color: $white !important;
  background-color: $green !important;
  border: 1px solid $white !important;
}
.cancel-action {
  color: $white !important;
  background-color: $alert-accented !important;
  border: 1px solid $white !important;
}

.mat-mdc-dialog-actions {
  justify-content: center !important;
}

.confirm-action:hover {
  filter: brightness(0.85);
}

.cancel-action:hover {
  filter: brightness(0.85);
}
