//PALETTE
$primary: #004;
// $primary: #003900;
$secondary: #e9e9e9;
$otherMenu: #323269;
$accent-primary: rgb(87, 132, 237);
$alert: #c60f13;
$alert-accented: #f53035;
$alert-background: #fcd6d6;
$accordion: #ddd;
$border-button: #cacaca;
$white: #fefefe;
// $black: #222;
$black: #000;
$grey: #c9c9c9;
$light-grey: #d9d9d9;
$success: #5da423;
$green: #50c878;
$dark-green: #008000;
$beige: #f5f5dc;
$warning: #efef008a;
$dialog-error-client: red;
$dialog-error-server: #ffa33f;
$dialog-progress-bar: #2f5d6d;
