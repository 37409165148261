import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-progress-dialog',
  templateUrl: './progress-dialog.component.html',
  styleUrl: './progress-dialog.component.scss',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatProgressBarModule],
  encapsulation: ViewEncapsulation.None,
})
export class ProgressDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<ProgressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string; filename: string }
  ) {}

  close() {
    this.dialogRef.close();
  }
}
