import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Environment } from 'csoft-library/modelli';
import {
  BehaviorSubject,
  firstValueFrom,
  Observable,
  Subject,
  tap,
} from 'rxjs';
import { HeaderService } from '../header.service';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private destroy$ = new Subject<void>();
  private _http: HttpClient;
  private TOKEN_STORAGE_KEY: string = 'AuthToken';

  // In order to handle multiple http req with an expired token inside the AuthInterceptor
  public isRefreshing = false;
  public refreshToken$: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);

  constructor(
    // Questo http handler permette di evitare di passare per gli Interceptors
    private bypassInterceptors: HttpBackend,
    private router: Router,
    @Inject('env') private environment: Environment,
    private Header: HeaderService
  ) {
    this._http = new HttpClient(this.bypassInterceptors);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public Login(username: string, password: string): Observable<any> {
    const url = `${this.environment.ApiDomain}api/auth/Login`;
    const loginModel = { username: username, password: password };
    return this._http.post<any>(url, loginModel, {
      withCredentials: true,
    });
  }

  public LogOutUser() {
    const url = `${this.environment.ApiDomain}api/auth/LogOut`;

    this._http
      .post(url, null, {
        headers: this.Header.getHeader(),
        withCredentials: true,
      })
      .subscribe({
        error: (err) => console.log(err),
        complete: () => {
          // Elimino jwt da localstorage
          this.deleteToken();

          if (
            !this.environment.production &&
            !this.environment.beta &&
            !this.environment.isEspana &&
            !this.environment.gestioneconsorzi
          ) {
            this.router.navigate(['/login']);
          } else {
            window.location.assign(this.environment.redirectUrl);
          }
        },
      });
  }

  private getToken() {
    return localStorage.getItem(this.TOKEN_STORAGE_KEY);
  }

  public setToken(token: string) {
    console.log('Setting token');
    localStorage.setItem(this.TOKEN_STORAGE_KEY, token);
  }

  public deleteToken() {
    console.log('Deleting token');
    localStorage.removeItem(this.TOKEN_STORAGE_KEY);
  }

  public RefreshToken(): Observable<string> {
    console.log('Refreshing token');
    const url = `${this.environment.ApiDomain}api/auth/GetToken`;
    return this._http
      .get<string>(url, {
        headers: this.Header.getHeader(),
        withCredentials: true,
      })
      .pipe(
        tap((token) => {
          this.setToken(token);
        })
      );
  }

  public async IsAuthenticated(): Promise<boolean> {
    const token =
      this.getToken() ??
      (await firstValueFrom(this.RefreshToken()).catch((err) => {
        console.error(err);
        return null;
      }));

    if (!token) {
      return false;
    }

    const payload: JwtPayload = jwtDecode(token);

    // LO UNIX TIME DEL TOKEN E' IN UTC
    if (DateTime.utc().toUnixInteger() > payload.exp) {
      return false;
    }

    return true;
  }

  public async getUserClaim() {
    const token =
      this.getToken() ??
      (await firstValueFrom(this.RefreshToken()).catch((err) => {
        console.error(err);
        return null;
      }));

    if (!token) return '';

    const payload: JwtPayload = jwtDecode(token);

    const currUsername = payload.sub;

    this.Header.username = currUsername;

    console.log('Returning user claim.');
    return currUsername;
  }
}
